/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;
@import '../node_modules/@eset/eset-shared/styles/styles.scss';

//TYPOGRAPHY
//Font CDN Import
@font-face {
  font-family: 'Fedra';
  src: url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Light.eot');
  src: url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Light.eot?#iefix') format('embedded-opentype'),
    url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Light.woff') format('woff'),
    url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Light.woff2') format('woff2'),
    url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Light.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Fedra';
  src: url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Light-Italic.eot');
  src: url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Light-Italic.eot?#iefix') format('embedded-opentype'),
    url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Light-Italic.woff') format('woff'),
    url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Light-Italic.woff2') format('woff2'),
    url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Light-Italic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Fedra';
  src: url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Book.eot');
  src: url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Book.eot?#iefix') format('embedded-opentype'),
    url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Book.woff') format('woff'),
    url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Book.woff2') format('woff2'),
    url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Book.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Fedra';
  src: url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Book-Italic.eot');
  src: url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Book-Italic.eot?#iefix') format('embedded-opentype'),
    url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Book-Italic.woff') format('woff'),
    url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Book-Italic.woff2') format('woff2'),
    url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Book-Italic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Fedra';
  src: url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Demi.eot');
  src: url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Demi.eot?#iefix') format('embedded-opentype'),
    url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Demi.woff') format('woff'),
    url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Demi.woff2') format('woff2'),
    url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Demi.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Fedra';
  src: url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Demi-Italic.eot');
  src: url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Demi-Italic.eot?#iefix') format('embedded-opentype'),
    url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Demi-Italic.woff') format('woff'),
    url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Demi-Italic.woff2') format('woff2'),
    url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Demi-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Fedra';
  src: url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Medium.eot');
  src: url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Medium.eot?#iefix') format('embedded-opentype'),
    url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Medium.woff') format('woff'),
    url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Medium.woff2') format('woff2'),
    url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Fedra';
  src: url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Medium-Italic.eot');
  src: url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Medium-Italic.eot?#iefix') format('embedded-opentype'),
    url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Medium-Italic.woff') format('woff'),
    url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Medium-Italic.woff2') format('woff2'),
    url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Medium-Italic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Fedra';
  src: url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Bold.eot');
  src: url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Bold.eot?#iefix') format('embedded-opentype'),
    url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Bold.woff') format('woff'),
    url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Bold.woff2') format('woff2'),
    url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Fedra';
  src: url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Bold-Italic.eot');
  src: url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Bold-Italic.eot?#iefix') format('embedded-opentype'),
    url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Bold-Italic.woff') format('woff'),
    url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Bold-Italic.woff2') format('woff2'),
    url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Bold-Italic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Fedra';
  src: url('https://static.eset.co.za/fonts/web/Fedra-Sans-Display-Heavy.eot');
  src: url('https://static.eset.co.za/fonts/web/Fedra-Sans-Display-Heavy.eot?#iefix') format('embedded-opentype'),
    url('https://static.eset.co.za/fonts/web/Fedra-Sans-Display-Heavy.woff') format('woff'),
    url('https://static.eset.co.za/fonts/web/Fedra-Sans-Display-Heavy.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Fedra Display';
  src: url('https://static.eset.co.za/fonts/web/Fedra-Sans-Display-Heavy.eot');
  src: url('https://static.eset.co.za/fonts/web/Fedra-Sans-Display-Heavy.eot?#iefix') format('embedded-opentype'),
    url('https://static.eset.co.za/fonts/web/Fedra-Sans-Display-Heavy.woff') format('woff'),
    url('https://static.eset.co.za/fonts/web/Fedra-Sans-Display-Heavy.ttf') format('truetype');
  font-style: normal;
}

body {
  font-family: 'Fedra', sans-serif;
  font-weight: 300;
  font-size: 16px;
}

select.ng-valid {
  @apply text-black;
}

@layer base {
  a {
    @apply text-primary cursor-pointer;
  }
  input {
    @apply border-2 border-solid border-grey-sky rounded p-2 w-full focus:outline-none focus:border-turquoise-bright h-10 font-normal;
  }

  select {
    @apply border-2 border-solid border-grey-sky rounded p-2 w-full focus:outline-none focus:border-turquoise-bright h-10 text-grey-mid;
  }

  button {
    @apply text-white bg-gradient-to-r from-turquoise-dark to-turquoise-bright hover:bg-gradient-to-br focus:ring-2 focus:outline-none font-medium rounded-full text-lg px-5 py-2 text-center my-4 disabled:bg-none disabled:bg-grey-concrete;
  }

  label {
    @apply block pb-1 text-grey-blue;
  }
}
ion-progress-bar {
  --background: theme('colors.turquoise.light');
  --progress-background: theme('colors.turquoise.mid');
}

ion-toast {
  --border-radius: 25px;
  --white-space: normal;
  width: 100%;
  @media screen and (max-width: 767px) {
    --height: auto;
  }
}

ion-toast::part(container) {
  text-align: center;
  font-family: 'Fedra', sans-serif;
  font-weight: 500;
}

ion-toast::part(message) {
  max-width: 500px;
}

ion-toast::part(button) {
  padding: 0px;
  padding-inline-end: 13px;
  height: 40px;
  color: theme('colors.white');
}

ion-toast.info-toast {
  --background: theme('colors.blue.electric');
  --color: theme('colors.white');
}

ion-toast.error-toast {
  --background: theme('colors.bright.crimson');
  --color: theme('colors.white');
}

ion-toast.warning-toast {
  --background: theme('colors.bright.orange');
  --color: theme('colors.white');
  font-weight: bold;
}

ion-toast.success-toast {
  --background: theme('colors.bright.green');
  --color: theme('colors.white');
}

ion-toast.auto-height {
  --height: auto;
}

.back-btn {
  width: 30px;
  height: 30px;
  opacity: 0.2;
  transition: all 0.3s ease-in-out;
  margin: -2.5px;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
}
